<template>
  <div
    v-if="restaurants.length > 0"
    class="d-flex align-items-center flex-wrap justify-content-between mt-2 mb-3"
  >
    <div class="hotels-grid" style="width: 100%">
      <div
        v-for="restaurant of restaurants"
        :key="restaurant.id"
        class="items my-2"
        style="cursor:pointer; user-select: none;"
        @click="openRestaurant(restaurant)"
      >
        <cld-image
          :publicId="restaurant.coverImage"
          class="img-fluid"
          style="z-index: 2;width: 100%;min-height: 174px;height:calc(100% - 40px); max-height: 200px;
          object-fit: cover; border-radius: 12px"
        >
          <cld-transformation height="400" width="600" crop="limit" />
        </cld-image>
        <div class="d-flex flex-column">
          <div
            class="font-weight-bolder text-dark font-medium-4 font-circular mt-1"
            style="min-height: 24px; height: 24px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 1; display: -webkit-box; -webkit-box-orient: vertical;"
          >
            {{ restaurant.name }}
          </div>
          <div
            class="d-flex justify-content-between align-items-center font-weight-bold"
            style="margin-top: 5px"
          >
            <span>Free Delivery</span>
            <span>26 minutes</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!restaurants || restaurants.length === 0" class="w-100 text-center pt-3">
      <h4>
        No restaurants found!
      </h4>
    </div>
  </div>
</template>

<style>
@media all and (max-width: 576px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
@media all and (min-width: 576px) and (max-width: 850px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
  }
}
@media all and (min-width: 850px) and (max-width: 1300px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3%;
  }
}
@media all and (min-width: 1312px) {
  .hotels-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3%;
  }
}
.items.card {
  border-radius: 2rem;
}
.items > .card-body {
  padding: 10px 0;
}
.items > .card-body .card-body {
  padding: 0;
}
</style>

<script>
// import { BModal } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    // BCol,
    // BRow,
    // BAspect,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    restaurants: {
      required: true,
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fetchingHotel: false,
      showDuplicateCartModal: false,
      badges: {
        popular: {
          title: 'POPULAR',
          variant: 'primary',
        },
        fast: {
          title: 'FAST DELIVERY',
          variant: 'primary',
        },
        free: {
          title: 'FREE DELIVERY',
          variant: 'primary',
        },
      },
    }
  },
  computed: mapState({
    hotel: state => state.hotel.hotel || {},
    user: state => state.user.user,
    showLogin: state => state.user.showLogin,
    cart: state => state.cart.added,
    cartCount: state =>
      state.cart.added.reduce(
        (item, current) => ({ quantity: item.quantity + current.quantity }),
        { quantity: 0 }
      ).quantity,
  }),
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    openRestaurant(restaurant, replace) {
      this.updateRestaurant(restaurant)
      if (replace) {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.replace({ name: 'restaurant', query: { id: restaurant._id } })
      } else {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.push({ name: 'restaurant', query: { id: restaurant._id } })
      }
    },
  },
}
</script>

<template>
  <div>
    <filters
      v-if="!($route.query['show-filter'] === 'false')"
      @filter="filter"
      @search="search"
    />
    <restaurants-list :restaurants="filteredRestaurants" />
  </div>
</template>

<script>
import restaurantsList from '@/components/RestaurantsList.vue'
import Filters from '@/components/category.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Restaurants',
  components: {
    restaurantsList,
    Filters,
  },
  data() {
    return {
      filteredRestaurants: [],
    }
  },
  computed: mapState({
    restaurants(state) {
      if (state.hotel.hotel) {
        if (this.$route.query.type === 'convenience') {
          return state.hotel.hotel.restaurants.filter(
            // eslint-disable-next-line no-underscore-dangle
            restaurant => restaurant._id === '60fb9744805e3610e4374ec2'
          )
        }
        if (!(this.$route.query.type === 'restaurants')) {
          return state.hotel.hotel.restaurants.filter(
            // eslint-disable-next-line no-underscore-dangle
            restaurant => restaurant._id !== '60fb9744805e3610e4374ec2'
          )
        }
        return state.hotel.hotel.dinein || []
      }
      return []
    },
    hotel: state => state.hotel.hotel || {},
    user: state => state.user.user,
    showLogin: state => state.user.showLogin,
    cart: state => state.cart.added,
    cartCount: state =>
      state.cart.added.reduce(
        (item, current) => ({ quantity: item.quantity + current.quantity }),
        { quantity: 0 }
      ).quantity,
  }),
  created() {
    this.filter()
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    async filter(category) {
      this.filteredRestaurants = this.restaurants.filter(restaurant => {
        return (
          !category ||
          (restaurant.categories && restaurant.categories.some(cat => cat === category))
        )
      })
    },
    async search(query) {
      if (!query) {
        this.filteredRestaurants = this.restaurants
        return
      }
      // eslint-disable-next-line no-param-reassign
      query = query.toLowerCase()
      this.filteredRestaurants = this.restaurants.filter(
        restaurant =>
          !query ||
          restaurant.name.toLowerCase().includes(query) ||
          (restaurant.menus &&
            restaurant.menus.some(menu => {
              return (
                menu.categories &&
                menu.categories.some(category => {
                  // console.log('category', menu, category.items)xxx
                  return (
                    category.items &&
                    category.items.some(item => {
                      return item.name.toLowerCase().includes(query)
                    })
                  )
                })
              )
            }))
      )
    },
  },
}
</script>

<style scoped></style>

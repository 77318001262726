<template>
  <b-row>
    <b-col md="12">
      <div class="form-label-group">
        <b-form-input
          id="floating-label1"
          style="border-radius: 4px; border: 2px solid #555;padding:13px"
          size="lg"
          placeholder="Search for restaurants, items, categories, etc."
          @input="$emit('search', $event)"
        />
      </div>
    </b-col>
    <b-col md="12">
      <div
        class="categories d-flex flex-row justify-content-between mt-1 mb-0 pb-1"
        style="overflow-x: auto; overflow-y: hidden"
      >
        <div
          v-for="category of categories"
          :key="category.value"
          class="cursor-pointer"
          @click="$emit('filter', category.id)"
        >
          <img
            :src="getIcon(category)"
            width="70"
            :alt="category.title"
            class="px-1 pt-1"
            style="padding-bottom: 6px"
          />
          <div class="text-center text-dark font-small-2 font-weight-bolder">
            {{ category.title }}
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormInput } from 'bootstrap-vue'

export default {
  components: { BRow, BCol, BFormInput },
  props: {
    categories: {
      type: Array,
      default: () => [
        // {
        //   value: 'alcohol',
        //   title: 'Alcohol',
        // },
        {
          id: '6114e9c9f97b1d2df0210b1e',
          value: 'breakfast',
          jpegIcon: true,
          title: 'Breakfast',
        },
        // {
        //   value: 'burger',
        //   title: 'Burger',
        // },
        // {
        //   value: 'chicken',
        //   title: 'Chicken',
        // },
        // {
        //   value: 'coffee',
        //   title: 'Coffee',
        // },
        {
          id: '6114e9c9f97b1d2df0210b1f',
          value: 'fastfood',
          jpegIcon: true,
          title: 'Fastfood',
        },
        {
          id: '6114e9c9f97b1d2df0210b20',
          value: 'healthy',
          jpegIcon: true,
          title: 'Healthy',
        },
        {
          id: '6114e9c9f97b1d2df0210b21',
          value: 'pizza',
          jpegIcon: true,
          title: 'Pizza',
        },
        // {
        //   value: 'sandwitch',
        //   title: 'Sandwitch',
        // },
        {
          id: '6114e9c9f97b1d2df0210b22',
          value: 'vegan',
          jpegIcon: true,
          title: 'Vegan',
        },
        {
          id: '6114e9c9f97b1d2df0210b23',
          value: 'glutenfree',
          jpegIcon: true,
          title: 'Gluten Free',
        },
        {
          id: '6114e9c9f97b1d2df0210b24',
          value: 'asian',
          jpegIcon: true,
          title: 'Asian',
        },
        {
          id: '6114e9c9f97b1d2df0210b25',
          value: 'mexican',
          jpegIcon: true,
          title: 'Mexican',
        },
      ],
    },
  },
  data() {
    return {}
  },
  methods: {
    getIcon(category) {
      if (category.jpegIcon)
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`@/assets/images/food-types/${category.value}.jpeg`)
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/food-types/${category.value}.svg`)
    },
  },
}
</script>

<style scoped>
.categories {
  scrollbar-width: none; /* Firefox */
  scrollbar-height: none; /* Firefox */
}
.categories::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
